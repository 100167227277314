<template>
  <v-card>
    <v-card-title>Adicionar Cliente</v-card-title>
    <v-form class="pa-5" @submit.prevent="postClient">
      <v-row>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="CPF"
          >

            <v-text-field
              label="CPF *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="cpf"
              v-mask="'###.###.###-##'"
            />

          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Nome"
          >
            <v-text-field
              label="Nome *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="name"
            />

          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Whatsapp"
          >

            <v-text-field
              label="Whatsapp"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="whatsapp"
              v-mask="'(##)#-####-####'"
            />

          </validation-provider>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Telefone/Celular"
            outlined
            class="ml-4"
            v-model="tel"
            v-mask="['(##)####-####', '(##)#####-####']"
          />

        </v-col>
      </v-row>

      <v-text-field
        label="Email"
        outlined
        class="ml-4"
        v-model="email"
      />

      <v-subheader class="display-1 mt-3">
        Endereço:
      </v-subheader>

      <v-row>
        <v-col md="2" sm="12" xs="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="CEP"
          >
            <v-text-field
              label="CEP *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="cep"
              @keyup="searchCep()"
              v-mask="'##.###-###'"
            />
          </validation-provider>
        </v-col>
      </v-row>


      
      <v-row>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Logradouro"
          >
            <v-text-field
              label="Logradouro *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="address"
            />
          </validation-provider>
        </v-col>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Complemento"
          >

            <v-text-field
              label="Complemento"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="complement"
            />

          </validation-provider>
        </v-col>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Número"
          >

            <v-text-field
              label="Número"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="number"
            />

          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Cidade"
          >
            <v-text-field
              label="Cidade *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="city"
            />
          </validation-provider>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Bairro"
          >
            <v-text-field
              label="Bairro *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="district"
            />
          </validation-provider>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Estado"
          >
            <v-text-field
              label="Estado *"
              outlined
              class="ml-4"
              :error-messages="errors"
              v-model="state"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-textarea
          name="input-7-1"
          label="Observação"
          value=""
          hint="observações"
          class="ml-4"
          v-model="ps"
        />
      </v-row>

      <v-card-actions class="pl-0">
        <v-btn
          color="success"
          min-width="100"
          type="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>


<script>

  import ClientsService from '@/services/ClientsService'
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import axios from 'axios'

  export default {
    name: 'AddClientFromOrder',

    data: () => ({
      name: "",
      document: "",
      whatsapp: "",
      email: "",
      tel: "",
      productmodel: "",
      failure: "",
      cpf: "",
      cep: "",
      address: "",
      complement: "",
      city: "",
      district: "",
      state: "",
      number: '',
      ps: "",

      saved: {},
    }),

    props: {
    },

    methods: {

      searchCep () {
        if(this.cep.length == 10) {
          axios.get(`https://viacep.com.br/ws/${ this.cep.replace('-', '').replace('.', '') }/json/`)
          .then((response) => {
            this.address = response.data.logradouro
            this.city = response.data.localidade
            this.district = response.data.bairro
            this.state = response.data.uf
          })
          .catch( error => console.log(error) )
        }
      },

      postClient() {
        let data = []

        data['name'] = this.name,
        data['document'] = this.document,
        data['whatsapp'] = this.whatsapp,
        data['email'] = this.email,
        data['tel'] = this.tel,
        data['cpf'] = this.cpf,
        data['cep'] = this.cep,
        data['address'] = this.address,
        data['complement'] = this.complement,
        data['city'] = this.city,
        data['district'] = this.district,
        data['state'] = this.state,
        data['number'] = this.number,
        data['ps'] = this.ps


        ClientsService.postClient(data,this.$route.params.id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Error"){
            throw 500
          }
          this.saved = response.data.client
          this.$toast.success('Cliente Salvo Sucesso.', '',{position:'topRight'})
          this.$emit('saved')
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },
    }
    
  }
</script>